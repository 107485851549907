import gql from 'graphql-tag';

export const createUserMutation = gql`
	mutation createUserMutation(
		$email: String!
		$firstName: String!
		$lastName: String!
		$groupPsId: UUID!
		$userRole: String!
		$organizationId: UUID!
		$file: Upload
		$countryCode: String
		$phoneNumber: String
	) {
		createUser(
			email: $email
			firstName: $firstName
			lastName: $lastName
			groupPsId: $groupPsId
			userRole: $userRole
			organizationId: $organizationId
			file: $file
			countryCode: $countryCode
			phoneNumber: $phoneNumber
		) {
			success
			profile {
				id
				psId
				firstName
			}
		}
	}
`;

export interface ICreateUserProfileMutation {
	id: string;
	psId: string;
	firstName: string;
}

export const TeamMemberEditMutation = gql`
	mutation TeamMemberEditMutation(
		$psId: UUID!
		$firstName: String
		$lastName: String
		$countryCode: String
		$phoneNumber: String
		$file: Upload
		$isSuperAdmin: Boolean
	) {
		updateProfile(
			psId: $psId
			firstName: $firstName
			lastName: $lastName
			countryCode: $countryCode
			phoneNumber: $phoneNumber
			file: $file
			isSuperAdmin: $isSuperAdmin
		) {
			success
		}
	}
`;
