import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import config from '@app/configs/au-main-config';
import { IUser } from '@app/shared/interfaces/user.interface';
import { IntercomKey, UserProfileQuery } from '@auth/graphql/auth-graphql';
import { Apollo } from 'apollo-angular';
import { map, pluck, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AuProfileService } from '@core/services/au-profile.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private http: HttpClient,
		private apollo: Apollo,
		private dialogRef: MatDialog,
		private auProfileService: AuProfileService
	) {}

	getCurrentUser() {
		return this.apollo
			.query<IUser>({
				query: UserProfileQuery,
				fetchPolicy: 'network-only',
			})
			.pipe(pluck('data', 'profile'));
	}

	getAndStoreUserProfile() {
		this.getCurrentUser().subscribe((profile: IUser) => {
			this.auProfileService.setUserProfile(profile);
		});
	}

	logIn(username: string, password: string): Observable<{ token: string }> {
		const body = { username, password };
		return this.http.post<{ token: string }>(config.portal.token_auth, body);
	}

	logout() {
		localStorage.removeItem(config.local_storage.auth_token);
		localStorage.removeItem(config.local_storage.current_user);
		// there are cases where material dialog could be opened when receiving 401
		// error, so this will close all modal windows trough app
		this.dialogRef.closeAll();
		// Cache need to be dropped for handling relogin by different accounts
		// without page reload
		this.clearApolloCacheStore();
	}

	clearApolloCacheStore() {
		// We should stop all ongoing requests
		this.apollo.client.stop();
		// And after that clear apollo store
		this.apollo.client.clearStore();
	}

	clearToken() {
		localStorage.removeItem(config.local_storage.auth_token);
	}

	loadAuthTranslation(): Observable<any> {
		return this.http.get(config.portal.auth_translation);
	}

	recoveryPassword(email: string): Observable<any> {
		return this.http.post<any>(config.portal.password_reset, { email });
	}

	sendNewPassword(newPassword: string, token: string): Observable<any> {
		const body = { new_password: newPassword };
		return this.http.patch(`${config.portal.password_reset}/${token}`, body);
	}

	setUserPassword(password: string, token: string) {
		return this.http.post(config.portal.set_password, {
			new_password: password,
			token,
		});
	}

	getIntercomKey() {
		return this.apollo
			.query<{ intercomKey: string }>({
				query: IntercomKey,
			})
			.pipe(
				take(1),
				map(object => object.data.intercomKey)
			);
	}
}
